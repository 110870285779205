.score-card {
  font-family: "Roboto";
  &__main-title {
    h3 {
      font-style: normal;
      font-weight: 300;
      font-size: 26px;
      line-height: 100%;
    }
  }
  &__icon-text {
    font-size: 12px;
    text-transform: uppercase;
  }

  &__decision {
    &-wrapper {
      margin-bottom: 30px;
    }

    &-text {
      font-weight: 700;
      font-size: 17px;
      line-height: 20px;
    }

    &-status {
      font-weight: 700;
      font-size: 17px;
      line-height: 20px;
      margin-left: 8px;
      color: green;
    }
  }

  &__reports {
    &-title {
      font-weight: 700;
      font-size: 17px;
      line-height: 20px;
    }
    &-item {
      border-bottom: 1px solid rgba(#818181, 0.5);
      display: flex;
      justify-content: space-between;
      padding: 0 0.5rem;

      &-left {
        display: flex;
        align-items: center;
        column-gap: 8px;
      }
      &-right {
        &__wrapper {
          button {
            height: 40px;
            margin-top: 12px;
            margin-right: 0px;
            margin-bottom: 12px;
            width: 40px;
          }
        }
        &__img {
          display: flex;
          align-items: center;
        }
      }

      &-name {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  &__decisioning {
    &-variables {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &-title {
      font-weight: 700;
      font-size: 17px;
      line-height: 20px;
    }
    &-left {
      flex-direction: column;
      row-gap: 10px;
    }

    &-right {
      flex-direction: column;
      row-gap: 10px;
    }

    &-output-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }

    &-input-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }

    &-output-value {
      border-radius: 3px;
      background: rgba(129, 129, 129, 0.1);
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      padding: 10px;
    }
    &-tier {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 7px;

      &-text {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
      }

      &-value {
        font-weight: 800;
        font-size: 25px;
        line-height: 29px;
      }
    }

    &-input-value {
      border-radius: 3px;
      background: rgba(129, 129, 129, 0.1);
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      padding: 10px;
    }

    &-input-item {
      display: flex;
      align-items: center;
    }

    &-content {
      column-gap: 20px;
      display: flex;
    }

    &-input-last-item {
      display: flex;
      justify-content: space-between;
    }
  }

  &__line {
    height: 1px;
    background: rgba(129, 129, 129, 0.5);
  }

  &__input-item {
    &-left {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
    }
    &-right {
      color: #818181;
      font-weight: 400;
      margin-left: 8px;
    }
  }
}
